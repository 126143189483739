import { template as template_1d1b927618d946428cff6e7ae756a16a } from "@ember/template-compiler";
const SidebarSectionMessage = template_1d1b927618d946428cff6e7ae756a16a(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

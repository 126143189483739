import { template as template_d6abb21d6f404780994bb37e0be2b759 } from "@ember/template-compiler";
const WelcomeHeader = template_d6abb21d6f404780994bb37e0be2b759(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;

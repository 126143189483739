import { template as template_f9177d60908b4f3cbaf31a700066607e } from "@ember/template-compiler";
const FKControlMenuContainer = template_f9177d60908b4f3cbaf31a700066607e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
